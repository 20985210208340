@import "bootstrap";

body,
html {
    width: 100%;
    height: 100%;
    font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
}

#privacypolicy {
  width: 66ex;
  margin: 0 auto;
}

.language-link {
  float: right;
  margin: 20px 30px;
  padding: 10px;
  img {
    border: 1px solid rgba(255, 255, 255, 0.6);
    width: 60px;
  }
}

.intro-header {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    color: #f8f8f8;
    background: url(../img/intro-bg.2b525553.jpg) no-repeat center center;
    background-size: cover;
}

.intro-message {
    position: relative;
    padding-top: 20%;
    padding-bottom: 20%;

    h1, h3 {
      text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
      font-weight: bold;
    }
}

.intro-divider {
    width: 400px;
    border-top: 1px solid #f8f8f8;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.social-button {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
}

#about {
  margin-top: 2em;
  margin-bottom: 2em;
}

.content-section-a {
    padding: 50px 0;
    background-color: #f8f8f8;
}

.content-section-b {
    padding: 50px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.section-heading {
    margin-bottom: 30px;
}

.contact {
    padding: 100px 0;

    a {
      font-size: 300%;
      font-weight: bold;
    }
}

footer {
    padding: 50px 0;
    background-color: #f8f8f8;
}
